import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Observable, take } from 'rxjs';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { tipoPropiedad } from '../../cards/cards.component';
import { AuthService } from '../../../services/auth.service';
import { DialogService } from '../../../services/dialog.service';
import { AlojamientosPostUseCase } from '../../../../core/usecase/alojamientos-post-use-case.service';
import { FavoritePostModel } from '../../../../core/domain/favorite-post-model';

@Component({
  selector: 'ln-card-maps',
  templateUrl: './ln-card-maps.component.html',
  styleUrls: ['./ln-card-maps.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnCardMapsComponent implements OnInit {

  @Input() data: any = {};
  textPrice: string = '';
  textBedBreakfast: string = '';
  isFavorite: boolean = false;
  favoritePostDeleted: any;
  @Output() refreshEvent = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private _translationService: TranslationService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _auth: AuthService,
    private _dialogService: DialogService,
    private _postService: AlojamientosPostUseCase,
  ) {
  }



  ngOnInit(): void {
    this.isFavorite = this.data.favoritos 

    this.textPrice = this.translateService.instant('card.labelPricePerNight')
    this.textBedBreakfast = this.translateService.instant('card.textFromPrice')
    this._translationService.onLangChange.subscribe(() => {
      this.textPrice = this.translateService.instant('card.labelPricePerNight')
      this.textBedBreakfast = this.translateService.instant('card.textFromPrice')
      this._cdr.detectChanges();
    });
  }


  getTranslation(text: string): Observable<string> {
    let key = 'Luna Nueva Properties'; // Set a default key
    if (text === 'BedBreakfast') {
      key = 'searchOption.textBed&Breakfast';
    } else if (text === 'Apartamentos') {
      key = 'searchOption.textApartment';
    } else if (text === 'Casas') {
      key = 'searchOption.textHouse';
    }
    return this.translateService.instant(key);
  }

  navigate() {

    if (this.data.propiedad[0].tipoPropiedad.includes("BedBreakfast")) {
      this._router.navigateByUrl(`/bb-profile/${this.data.idPropiedad}`);
    }
    else {
      this._router.navigateByUrl(`/post-details/${this.data.idAlojamiento}`);
    }

  }



  markAsFavorite() {
    if (this._auth.isLogedin) {
      const favoritePostModel = {
        postId: this.data.idAlojamiento,
      };      
      this._postService.markAsFavorite(favoritePostModel.postId)
        .pipe(take(1))
        .subscribe({
          next: () => {
              this.isFavorite = !this.isFavorite;
              this.triggerRefresh();
              this._cdr.detectChanges
          },
          error: () => {
            this.isFavorite = !this.isFavorite;
            this.triggerRefresh();
            this._cdr.detectChanges
          }
        });
    } else {
      this._dialogService.openLoginRegistSessionDialog()
        .pipe(take(1))
    }
  }

  triggerRefresh(): void {
    this.refreshEvent.emit();
  }
}
