import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ResultadoDescuento } from "../interfaces/descuentos-interface";
import moment from 'moment-timezone';

export function contarNoches(fechaInicioStr: string, fechaFinStr: string): number {
	// Convertimos las fechas a objetos Date
	const fechaInicio = new Date(fechaInicioStr);
	const fechaFin = new Date(fechaFinStr);

	// Calculamos la diferencia en milisegundos entre las dos fechas
	const diferenciaTiempo = fechaFin.getTime() - fechaInicio.getTime();

	// Convertimos la diferencia de milisegundos a días
	const noches = diferenciaTiempo / (1000 * 60 * 60 * 24);

	return noches;
}


/***
 * Método para convertir timestamp a formato NgbDateStruct
 * @param timestamp: fecha en formato timestamp
 * @returns objeto NgbDateStruct
 */
export function timestampToNgbDateStruct(timestamp: string): NgbDateStruct {
	const date = new Date(Date.parse(timestamp));
	return {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate() + 1
	}
}

/***
 * Método para convertir timestamp a formato NgbDateStruct
 * @param date: fecha en formato Date
 * @returns objeto NgbDateStruct
 */
export function dateToNgbDateStruct(date: Date): NgbDateStruct {
	return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}


/***
 * Método para convertir NgbDateStruct a formato NgbDate
 * @param date: fecha en formato NgbDateStruct
 * @returns objeto NgbDate
 */
export function ngbDateStructToNgbDate(dateStruct: NgbDateStruct | null): NgbDate | null {
	if (dateStruct === null) {
		return null;
	}
	return new NgbDate(dateStruct.year, dateStruct.month, dateStruct.day);
}

/***
 * Método para convertir NgbDate a formato NgbDateStruct
 * @param date: fecha en formato NgbDate
 * @returns objeto NgbDateStruct
 */
export function ngbDateToNgbDateStruct(date: NgbDate | null): NgbDateStruct | null {
	if (date === null) {
		return null;
	}
	return { year: date.year, month: date.month, day: date.day };
}

/**
 * Validador personalizado para un campo de número de teléfono.
 * Este validador verifica que el número de teléfono tenga exactamente 8 dígitos,
 * ignorando cualquier otro carácter no numérico (como paréntesis, guiones, espacios, etc.).
 * 
 * @returns Un objeto de error con la clave `invalidPhone` si la validación falla, o `null` si la validación es exitosa.
 */

/**
* Validador personalizado para un campo de correo.
* Este validador verifica que el sea valido,
* Permite bloquear el dominio de algun servidor de correo no deseado
* 
* @returns Un objeto de error con la clave `forbiddenDomain` si la validación falla, o `null` si la validación es exitosa.
*/
export function emailDomainValidator(forbiddenDomain: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const email = control.value;
		if (email && email.includes('@')) {
			const domain = email.split('@')[1];
			if (domain.toLowerCase() === forbiddenDomain.toLowerCase()) {
				return { forbiddenDomain: true };
			}
		}
		return null;
	};


}

export const getPacificTime = (): string => {
	return moment().tz("America/Los_Angeles").format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export function detectarPatron(patron: string, texto: string) {
	const regex = new RegExp(patron, 'i');
	return regex.test(texto);
}