import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ServicePetsComponent } from '../../service-pets/service-pets.component';
import { AlojamientosFilters } from '../../../interfaces/alojamientos-filters.interface';
import { IsPetService } from '../../../services/isPet.service';
import { StoreFiltersService } from '../../../services/storeFilters.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-ln-guest-selector',
	templateUrl: './ln-guest-selector.component.html',
	styleUrls: ['./ln-guest-selector.component.css', './2ln-guest-selector.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnGuestSelectorComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	@Output() adultsChange = new EventEmitter<number>();
	@Output() petsChange = new EventEmitter<number>();
	@Output() infantsChange = new EventEmitter<number>();
	@Output() childrenChange = new EventEmitter<number>();

	@Input() adults: number = 1;
	@Input() pets: number = 0;
	@Input() infants: number = 0;
	@Input() children: number = 0;
	@Input() reset: boolean | undefined;
	@Input() customClass: string = '';
	@Input() maxGuests: number = 50;
	@Input() mascotas: boolean = false;

	maxPets: number = 2;
	guests: number = 0;

	defaultAdults: number = 2;
	buttonText: string = '';


	@ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

	filtersAlojamientoGuest: AlojamientosFilters = {};
	isBedBreakfast: boolean = false;

	constructor(
		private elementRef: ElementRef,
		private translate: TranslateService,
		private _filtersService: StoreFiltersService,
		private petService: IsPetService,
	) {
		this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateButtonText();
		});
	}

	ngOnInit(): void {
		this._filtersService.isBedBreakfast$.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.isBedBreakfast = value;
		});
		this.pets = this.petService.petQuantity;

		this.petService.petSelected$.pipe(takeUntil(this.destroy$)).subscribe(hasPet => {
			if (hasPet) {
				this.pets = this.petService.petQuantity;
			} else {
				this.pets = this.petService.petQuantity;
			}
			this.updateButtonText();
		});

		// Cargas los filtros cargados anteriormente si es que los hay
		const previousFilters = this._filtersService.getAnyFilter();
		if (previousFilters.type === 'BedBreakfast') {
			this.isBedBreakfast = true;
		}
		if (previousFilters === 'Adults' || previousFilters === 'Children' || previousFilters === 'Infants' || previousFilters === 'Pets') {
			this.adults = parseInt(previousFilters.Adults) || 2;
			this.children = parseInt(previousFilters.Children) || 0;
			this.infants = parseInt(previousFilters.Infants) || 0;
			this.pets = parseInt(previousFilters.Pets) || 0;

			this.filtersAlojamientoGuest = {
				Adults: this.adults,
				Infants: this.infants,
				Children: this.children,
				Pets: this.pets,
			};
			this._filtersService.setFilter(this.filtersAlojamientoGuest);
		}else if (previousFilters !== 'Adults' || previousFilters !== 'Children' || previousFilters !== 'Infants') {
			this.adults = parseInt(previousFilters.Adults) || 2;
			this.children = parseInt(previousFilters.Children) || 0;
			this.infants = parseInt(previousFilters.Infants) || 0;
			this.pets = parseInt(previousFilters.Pets) || 0;

			this.filtersAlojamientoGuest = {
				Adults: this.adults,
				Infants: this.infants,
				Children: this.children,
				Pets: this.pets,
			};
			this._filtersService.setFilter(this.filtersAlojamientoGuest);
		}

		this.updateButtonText();
		this.calculateGuests();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	calculateGuests() {
		this.guests = this.adults + this.children;
		return this.guests;
	}

	decreaseAdults() {
		this.adults = this.adults <= 1 ? 1 : this.adults - 1;
		this.adultsChange.emit(this.adults);
		this.calculateGuests();
		this.updateButtonText();
	}

	increaseAdults() {
		this.adults = this.adults + 1;
		this.adultsChange.emit(this.adults);
		this.calculateGuests();
		this.updateButtonText();
	}

	increaseChildren() {
		this.children = this.children + 1;
		this.childrenChange.emit(this.children);
		this.calculateGuests();
		this.updateButtonText();
	}

	decreaseChildren() {
		this.children = this.children <= 0 ? 0 : this.children - 1;
		this.childrenChange.emit(this.children);
		this.calculateGuests();
		this.updateButtonText();
	}

	increaseInfants() {
		this.infants = this.infants + 1;
		this.infantsChange.emit(this.infants);
		this.updateButtonText();
	}

	decreaseInfants() {
		this.infants = this.infants <= 0 ? 0 : this.infants - 1;
		this.infantsChange.emit(this.infants);
		this.updateButtonText();
	}

	increasePets() {
		this.pets = this.pets + 1;
		this.petService.petQuantity = this.pets;
		this.petsChange.emit(this.pets);
		this.updateButtonText();
	}

	decreasePets() {
		this.pets = this.pets <= 0 ? 0 : this.pets - 1;
		this.petService.petQuantity = this.pets;
		this.petsChange.emit(this.pets);
		this.updateButtonText();
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const clickedInside = this.elementRef.nativeElement.contains(event.target);
		if (!clickedInside) {
			// Lógica adicional si es necesario
		}
	}

	private _matDialog = inject(MatDialog);
	showAnimal() {
		this._matDialog.open(ServicePetsComponent, {
			disableClose: true,
			panelClass: 'ln-dialog-pets',
		});
	}

	updateButtonText() {
		this.buttonText = this.getButtonText();
	}

	getButtonText(): string {
		let text = '';
		let guest = this.adults + this.children;

		if (guest > 0) {
			text += `${guest} ${this.translate.instant('guestSelector.textGuests')}${guest > 1 ? '' : ''}, `;
		}

		if (this.infants > 0) {
			text += `${this.infants} ${this.translate.instant('guestSelector.textInfants')}${this.infants > 1 ? '' : ''}, `;
		}
		if (this.pets > 0) {
			text += `${this.pets} ${this.translate.instant('guestSelector.textPets')}${this.pets > 1 ? '' : ''}, `;
		}
		return text ? text.slice(0, -2) : this.translate.instant('guestSelector.textButton');
	}

	@Output() resetChange = new EventEmitter<boolean>();

	ngOnChanges(changes: SimpleChanges) {
		if (changes['reset'] && changes['reset'].currentValue === true) {
			this.resetGuests();
		}
		this.updateButtonText();
	}

	resetGuests() {
		this.adults = this.defaultAdults;
		this.children = 0;
		this.infants = 0;
		this.pets = 0;
		this.calculateGuests();
		this.updateButtonText();
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll() {
		this.trigger.closeMenu();
	}
}