import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AlojamientosFilters } from "../../shared/interfaces/alojamientos-filters.interface";
import { AlojamientosResponseModel } from '../domain/mapa/alojamiento-filters-model';

@Injectable()
export abstract class AlojamientosRepository {
	abstract getPostDetails(id: string): Observable<any>
	abstract getAlojamientosByFilters(filters?: AlojamientosFilters): Observable<AlojamientosResponseModel[]>
	abstract getAlojamientosCoordenadas(filters?: AlojamientosFilters): Observable<AlojamientosResponseModel[]>
	abstract getPrices(): Observable<any[]>
	abstract markAsFavorite(id: string): Observable<any>
	abstract getFavorites(filters?: AlojamientosFilters): Observable<AlojamientosResponseModel[]>
	abstract getAlojamientoTarifa(id: string, fechaDesde: string, fechaHasta: string): Observable<any>
}