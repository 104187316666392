import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreFiltersService {
  private filters: any = {};
  private filterSide: any = {};
  private orderFilter: any = {};
  private isBedBreakfast: boolean = false;
  public filterChanged: EventEmitter<any> = new EventEmitter();

  private filterSideSubject = new BehaviorSubject<any>(this.filterSide);
  private isBedBreakfastSubject = new BehaviorSubject<boolean>(this.isBedBreakfast);
  isBedBreakfast$ = this.isBedBreakfastSubject.asObservable();

  constructor() {
    this.loadFiltersFromSession();
    this.loadFilterSideFromSession();
    this.loadOrderFilterFromSession();
  }

  setFilter(value: any): void {
    if (value.hasOwnProperty('type') && value.type === 'BedBreakfast') {
      this.isBedBreakfast = true;
      this.isBedBreakfastSubject.next(this.isBedBreakfast);
    }
    else if (value.hasOwnProperty('type') && value.type !== 'BedBreakfast') {
      this.isBedBreakfast = false;
      this.isBedBreakfastSubject.next(this.isBedBreakfast);
    }
    if (!this.filters) {
      this.filters = value;
    } else {
      for (let key in value) {
        if (this.filters[key] !== value[key]) {
          this.filters[key] = value[key];
        }
      }
    }
    this.saveFiltersToSession();
  }

  deleteFilter(keyToDelete: string): void {
    if (keyToDelete === 'type' && this.isBedBreakfast) {
      this.isBedBreakfast = false;
      this.isBedBreakfastSubject.next(this.isBedBreakfast);
    }
    if (this.filters.hasOwnProperty(keyToDelete)) {
      delete this.filters[keyToDelete];
      this.saveFiltersToSession(); // Asegurarse de guardar después de eliminar
    }
    if(keyToDelete==='permiteMascotas'){
      delete this.filters['Pets'];
      this.saveFiltersToSession();
    }
  }

  getFilter(): any {
    return this.filters;
  }

  clearFilters(): void {
    this.filters = {};
    this.saveFiltersToSession();
  }

  setFilterSide(value: any): void {
    if (!this.filterSide) {
      this.filterSide = value;
    } else {
      for (let key in value) {
        if (this.filterSide[key] !== value[key]) {
          this.filterSide[key] = value[key];
        }
      }
    }
    this.filterSideSubject.next(this.filterSide);
    this.saveFilterSideToSession();
  }

  getFilterSide(): any {
    return this.filterSide;
  }

  filterSideChanges(): Observable<any> {
    return this.filterSideSubject.asObservable();
  }

  deleteFilterSide(keyToDelete: any): void {
    if (this.filterSide.hasOwnProperty(keyToDelete)) {
      delete this.filterSide[keyToDelete];
      this.filterSideSubject.next(this.filterSide);
      this.saveFilterSideToSession();
    }
  }

  deleteAnyFilter(keyToDelete: any): void {
    this.deleteFilterSide(keyToDelete);
    this.deleteFilter(keyToDelete);
    this.deleteOrderFilter(keyToDelete);
  }

  clearFiltersSide(): void {
    this.filterSide = {};
    this.filterSideSubject.next(this.filterSide);
    this.saveFilterSideToSession();
  }

  getAnyFilter(): any {
	this.loadFilterSideFromSession();
	this.loadFiltersFromSession();
	this.loadOrderFilterFromSession();
    return { ...this.filters, ...this.filterSide, ...this.orderFilter };
  }

  setOrderFilter(value: any): void {
    if (!this.orderFilter) {
      this.orderFilter = value;
    } else {
      for (let key in value) {
        if (this.orderFilter[key] !== value[key]) {
          this.orderFilter[key] = value[key];
        }
      }
    }
    this.saveOrderFilterToSession();
  }

  getOrderFilter(): any {
    return this.orderFilter;
  }

  deleteOrderFilter(value: any): void {
    const keyToDelete = Object.keys(value)[0];
    delete this.orderFilter[keyToDelete];
    this.saveOrderFilterToSession();
  }
  clearOrderFilters(): void {
    this.orderFilter = {};
    this.saveOrderFilterToSession();
  }

  emitFilterSideChange(data: any) {
    this.filterChanged.emit(data);
  }

  clearAllFilters(): void {
    this.clearFilters();
    this.clearFiltersSide();
    this.clearOrderFilters();
  }

  private saveFiltersToSession(): void {
    sessionStorage.setItem('filters', JSON.stringify(this.filters));
  }

  private loadFiltersFromSession(): void {
    const savedFilters = sessionStorage.getItem('filters');
    if (savedFilters) {
      this.filters = JSON.parse(savedFilters);
    }
  }

  private saveFilterSideToSession(): void {
    sessionStorage.setItem('filterSide', JSON.stringify(this.filterSide));
  }

  private loadFilterSideFromSession(): void {
    const savedFilterSide = sessionStorage.getItem('filterSide');
    if (savedFilterSide) {
      this.filterSide = JSON.parse(savedFilterSide);
    }
  }

  private saveOrderFilterToSession(): void {
    sessionStorage.setItem('orderFilter', JSON.stringify(this.orderFilter));
  }

  private loadOrderFilterFromSession(): void {
    const savedOrderFilter = sessionStorage.getItem('orderFilter');
    if (savedOrderFilter) {
      this.orderFilter = JSON.parse(savedOrderFilter);
    }
  }
}