import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Swiper } from 'swiper';
import { Router } from '@angular/router';
import { carouseldataInterface } from '../../interfaces/carousel-publisher.interface';
import { environment } from '../../../../environments/environment';
import { connectStorageEmulator } from '@angular/fire/storage';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PublisherRepository } from '../../../core/repositories/publisher.repository';



@Component({
	selector: 'ln-carousel-publisher',
	templateUrl: './ln-carousel-publisher.component.html',
	styleUrls: [
		'./ln-carousel-publisher.component.scss',
		'./ln-carousel-publisher2.components.css',
	],
})
export class LnCarouselPublisherComponent implements OnInit, AfterViewInit {
	@Input() titulo: string = '';
	@Input() viewAllRedirect: string = '';
	@Input() redirectionDetail: string = '';
	srcEnv = environment.firebaseConfig.storageBucket + '/';
	carouselData: any[] = [];
	swiper: Swiper | undefined;
	isData = false;
	bedData = false;
	subscriptions: Subscription[] = [];

	private bedAndbreakfastcarouselDataSubject = new BehaviorSubject<
		any[] | null
	>(null);
	bedAndbreakfastcarouselData$: Observable<any[] | null> =
		this.bedAndbreakfastcarouselDataSubject.asObservable();

	constructor(
		private router: Router,
		private _cdr: ChangeDetectorRef,
		private _publisherRepository: PublisherRepository
	) { }

	ngOnInit(): void {
		const bedAndBreakfastSubscription = this._publisherRepository
			.get()
			.subscribe(
				(data) => {
					if (Array.isArray(data)) {
						this.carouselData = data;
						this.isData = true;
						if (this.carouselData.length > 0) {
							this._cdr.detectChanges();
							this.swiperConfig();
							this.router.events.subscribe((event) => {
								this.swiper?.update();
								this.swiper?.slideTo(0);
							});
						}

					} else {
						console.error('Error: Expected data to be an array', data);
						this.bedAndbreakfastcarouselDataSubject.next(null); // Emitir null en caso de error
					}
					this.bedData = true;
				},
				(error) => {
					console.error('Error fetching carousel data', error);
					this.bedAndbreakfastcarouselDataSubject.next(null); // Emitir null en caso de error
				}
			);
		this.subscriptions.push(bedAndBreakfastSubscription);
	}

	ngAfterViewInit(): void {
		//this.swiperConfig();
	}

	swiperConfig() {
		this.swiper = new Swiper('.swiper', {
			direction: 'horizontal',
			slidesPerView: 'auto',
			cssMode: true,
			//revisar bullets extras
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 'auto',
					centeredSlides: true,
					watchOverflow: true,
				},
				768: {
					slidesPerView: 2,
					watchOverflow: true,
					centerInsufficientSlides: true,
				},
				1024: {
					slidesPerView: 3,
					centerInsufficientSlides: true,
					spaceBetween: 20,
				},
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			resizeObserver: true,
			updateOnWindowResize: true,
		});
	}

	goToDetail(id: number) {
		this.router.navigateByUrl(`${this.redirectionDetail}/${id}`);
	}
	goToSeeAll() {
		this.router.navigateByUrl(this.viewAllRedirect);
	}
}
