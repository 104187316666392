<div class="hintContainer">
	<article class="card">

		<div class="mainImage">
			<img [src]="data.imageUrl" alt="" />

		</div>

		<div class="info-container">
			<div clase="card-header">
				<span class="title">
					<h1>{{'reservationSuccess.textTitle' | translate}}</h1>
				</span>
				<span>
					<p class="successfulReservation">{{'successfulContact.text1' | translate}}</p>
				</span>

			</div>
			<div class="accomodationTypeContainer">
				<p *ngIf="!isBedAndBreakfast" class="t2">{{data.accomodationType}}</p>
				<p *ngIf="isBedAndBreakfast" class="t2">{{data.accomodationType}} {{data.bbName}}</p>
			</div>
			<p *ngIf="isBedAndBreakfast" class="bbbuilding">{{data.bbBuildingName}}</p>


			<div *ngIf="!isBedAndBreakfast" class="detailsRoom-container">
				<div class="flex">
					<img src="../../../../assets/icons/bedroom.svg" alt="" />
					<p *ngIf="data.bedrooms<=1">{{data.bedrooms}}
						{{'card.labelBedroom'|translate}}</p>
					<p *ngIf="data.bedrooms>1">{{data.bedrooms}}
						{{'card.labelBedrooms'|translate}}</p>
				</div>
				<div class="flex">
					<img src="../../../../assets/icons/shower.svg" alt="" />
					<p *ngIf="data.bathrooms<=1">{{data.bathrooms}}
						{{'card.labelBathroom'|translate}}</p>
					<p *ngIf="data.bathrooms>1">{{data.bathrooms}}
						{{'card.labelBathrooms'|translate}}</p>
				</div>
			</div>

			<div *ngIf="isBedAndBreakfast" class="detailsRoom-container">
				<div class="camas-container">
					<div  class="cama-item">
						<img class="icons" src="../../../../assets/icons/hotel.svg">
						<p class="aligned-with-icon">
							{{ data.bedrooms }} {{ data.bedType| translate }}
						</p>
					</div>

					<div class="flex items-center" >
						<img class="icons" src="../../../../assets/icons/shower.svg" alt="" />
						<p *ngIf="data.bathrooms >1 && !data.bathroomType">{{data.bathrooms}} {{'card.labelBathrooms'|translate}}</p>
						<p *ngIf="data.bathrooms <=1 && !data.bathroomType">{{data.bathrooms}} {{'card.labelBathroom'|translate}}</p>
						<p *ngIf="data.bathroomType">{{data.bathrooms}} {{data.bathroomType}}</p>
					</div>

				</div>
			</div>

			<div class="card-footer">
				<div class="location-container">
					<img src="../../../../assets/icons/location-solid.svg" alt="" />
					<h2 class="location">{{data.address}} </h2>
				</div>
				<p> {{'card.textDistance1'|translate}} {{data.downtownDistance}} km
					{{'card.textDistance2'|translate}}</p>
			</div>

			<div class="total-container">
				<p>{{'card.textTotalImpuestos' | translate}}</p>
				<h3>{{data.totalCost | currency: 'USD':'symbol':'1.2-2'}}</h3>
			</div>


		</div>
	</article>
	<span class="button">
		<button mat-button mat-dialog-close class="buttonClose">
			{{ "reservationSuccess.textToReservations" |translate }}
		</button>
	</span>

</div>